import 'bootstrap/dist/js/bootstrap.bundle'

import 'smooth-scroll/dist/smooth-scroll.polyfills'

import './components/sticky-navbar'
import './components/smooth-scroll'
import './components/scroll-top-button'
import './components/password-visibility-toggle'
import './components/element-parallax'
import './components/parallax'
import './components/carousel'
import './components/gallery'
import './components/range-slider'
import './components/form-validation'
import './components/input-formatter'
import './components/tooltip'
import './components/popover'
import './components/toast'
import './components/video-button'
import './components/price-switch'
import './components/masonry-grid'
import './components/subscription-form'
import './components/hover-animation'
import './components/audio-player'
;(function () {
  window.onload = function () {
    const preloader = document.querySelector('.page-loading')

    preloader.classList.remove('active')

    setTimeout(function () {
      preloader.remove()
    }, 1000)

    const form = document.getElementById('form-contact')
    //const button = document.getElementById('btn-submit-contact')
    //const formContactError = document.getElementById('form-contact-error')
    //const formContactSuccess = document.getElementById('form-contact-success')

    form.addEventListener(
      'submit',
      async function (event) {
        event.preventDefault()

        document.querySelectorAll('.alert').forEach((el) => {
          el.classList.add('d-none')
        })

        form.classList.add('was-validated')

        const formData = new FormData(form)
        const formValues = Object.values(Object.fromEntries(formData))

        for (let i = 0; i < formValues.length; i++) {
          const formInputValue = formValues[i]
          if (!formInputValue) {
            return
          }
        }

        await sendEmail(Object.fromEntries(formData))
      },
      false
    )

    async function sendEmail(formData) {
      document.getElementById('btn-submit-contact').classList.add('d-none')
      document
        .getElementById('btn-submit-contact-loader')
        .classList.remove('d-none')

      const result = await fetch('https://www.virtualmoov.com/contact.php', {
        method: 'POST',
        body: JSON.stringify(formData),
      })
        .then(function (res) {
          return res.json()
        })
        .then(function (json) {
          document
            .getElementById('btn-submit-contact')
            .classList.remove('d-none')
          document
            .getElementById('btn-submit-contact-loader')
            .classList.add('d-none')

          if (json.error) {
            document
              .getElementById('form-contact-error')
              .classList.remove('d-none')
            return false
          }
          if (json.success) {
            document
              .getElementById('form-contact-success')
              .classList.remove('d-none')
            form.classList.remove('was-validated')
            form.reset()
          } else {
            document
              .getElementById('send-email-error')
              .classList.remove('d-none')
          }

          return json.success
        })

      return result
    }
  }
})()
